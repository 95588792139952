import React from 'react';
import './style/Loader.css'; // Import CSS Module

const Loader = ({ iconPath = '/path/to/your-icon.png', size = 100 }) => {
  return (
    <div
      className="loading-icon"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${iconPath})`,
      }}
    ></div>
  );
};

export default Loader;


