import React, { useEffect, useState } from "react";
import axios from "axios";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons/faProjectDiagram";
import { useParams, useLocation } from "react-router-dom";
import SocialMedia from "./SocialMedia";

const Header = ({ setEvent, social_urls}) => {
  const [event, setEventState] = useState({});
  const [loadingEid, setLoadingEid] = useState(false);
  const { event_id } = useParams();
  const location = useLocation(); // Get the current location

  // Check if the current route contains "admin"
  const isAdminRoute = location.pathname.includes("admin");

  const updateEvent = (eventData) => {
    setEvent(eventData); // Update the parent state
    setEventState(eventData); // Update the local state
  };

  useEffect(() => {
    const fetchEventName = async () => {
      try {
        setLoadingEid(true);
        // Use the environment variable here
        const response = await axios.get(
          process.env.REACT_APP_MODEL_ENDPOINT + `/check_eid?eid=${event_id}`
        );
        if (response.data && response.data.success) {
          updateEvent(response.data.event);
        } else {
          console.error("invalid event id:", response.data);
          updateEvent(false);
        }
      } catch (error) {
        updateEvent(false);
      } finally {
        setLoadingEid(false);
      }
    };

    fetchEventName();
  }, [event_id]);

  return (
    <>
    
    <div className="border-bottom mb-3">
      {loadingEid ? (
        ""
      ) : event ? (
        // company logo and name
        <div className="d-flex justify-content-center align-items-center mb-2">
          {/* company logo at the left corner */}
          <div className="me-auto align-items-center">
            {/* logo */}
            <img
              src={event.logo || "/mygallery192.png"}
              alt="Company Logo"
              //   className="rounded-circle"
              style={{ width: "25px", height: "25px" }}
            />
            {/* name */}
            <span className="mx-2">
              <strong>{event.company_name || "Jo'Events"}</strong>
              {isAdminRoute && <small> - Admin</small>}              
            </span>
          </div>
            {/* Events infos: center */}

        <div className="ms-auto">
          {/* Social Media Links */}
          <SocialMedia social_urls={social_urls} color="text-dark"/>
        </div>
        </div>
      ) : (
        <h2 className="text-center mb-4">Event not found</h2>
      )}
    </div>

    <div className="text-center d-flex justify-content-center">
      <div>
        
              <h3>
   {/* if route is {id}/admin then add admin */}
              {event.name}
              </h3>
              <div className="d-flex justify-content-center">
              <small>
                  {event.date && event.date}
                  {event.location && (
                    <span className=""> - {event.location}</span>
                  )}
                </small>
        </div>

      </div>


      </div>

    </>
  );
};

export default Header;
