// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .loading-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    animation: spin 2s linear infinite, pulse 1.5s ease-in-out infinite;
    /* Ensure proper rendering across browsers */
    transform-origin: center;
    animation: spin 2s linear infinite, pulse 1.5s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/style/Loader.css"],"names":[],"mappings":"EAAE;IACE,4BAA4B;IAC5B,2BAA2B;IAC3B,wBAAwB;IACxB,mEAAmE;IACnE,4CAA4C;IAE5C,wBAAwB;IAExB,mEAAmE;EACrE;;EAaA;IACE;MAEE,uBAAuB;IACzB;IACA;MAEE,yBAAyB;IAC3B;EACF;;EAaA;IACE;MAEE,mBAAmB;IACrB;IACA;MAEE,qBAAqB;IACvB;EACF","sourcesContent":["  .loading-icon {\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: contain;\n    animation: spin 2s linear infinite, pulse 1.5s ease-in-out infinite;\n    /* Ensure proper rendering across browsers */\n    -webkit-transform-origin: center;\n    transform-origin: center;\n    -webkit-animation: spin 2s linear infinite, pulse 1.5s ease-in-out infinite;\n    animation: spin 2s linear infinite, pulse 1.5s ease-in-out infinite;\n  }\n  \n  @-webkit-keyframes spin {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n  \n  @keyframes spin {\n    0% {\n      -webkit-transform: rotate(0deg);\n      transform: rotate(0deg);\n    }\n    100% {\n      -webkit-transform: rotate(360deg);\n      transform: rotate(360deg);\n    }\n  }\n  \n  @-webkit-keyframes pulse {\n    0%, 100% {\n      -webkit-transform: scale(1);\n      transform: scale(1);\n    }\n    50% {\n      -webkit-transform: scale(1.1);\n      transform: scale(1.1);\n    }\n  }\n  \n  @keyframes pulse {\n    0%, 100% {\n      -webkit-transform: scale(1);\n      transform: scale(1);\n    }\n    50% {\n      -webkit-transform: scale(1.1);\n      transform: scale(1.1);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
