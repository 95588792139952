import ImageGallery from "./../components/ImageGallery";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faUpload } from "@fortawesome/free-solid-svg-icons";

const ImageManager = ({ event }) => {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();


  const fetchImages = async () => {
    setIsUploading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_MODEL_ENDPOINT + `/images?eid=${event.id}`
      );

      if (typeof response.data !== "object") {
        console.log("Error fetching images:", response.data);
        return;
      }

      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsUploading(false);
    }
  };


  const handleRedirect = (path) => {
    // prevent default action
    if (path) {
      return () => navigate(`/${event.id}/${path}`);
    } else {
      return () => navigate(`/${event.id}`);
    }
  };

  // retrieve all the images uploaded for the event on load
  useEffect(() => {
    fetchImages();
  }, [event.id]);

  return (
    /* Show the event name */
    event && (
      <>
        <div className="container rounded p-2 m-1">
          {/* spinner when is uploading */}
          {isUploading && (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}


          {/* show gallery is images found, else show now images found */}
          {images.length > 0 ? (
            /* Image Gallery component */
            <>
                      {/* Upload more*/}
          <div className="container m-1">
            <Col>
              <Col className="m-2 text-center"><strong>{images.length} Images available</strong></Col>
              <Col className="text-center mb-3">
              {/* redirection to /admin/upload */}
                <Button className="m-1" variant="secondary" onClick={handleRedirect('')}>                  
                  Magic link
                </Button>
                <Button className="m-1" variant="success" onClick={handleRedirect('admin/upload')}>                  
                  + Upload more
                </Button>
              </Col>
            </Col>
          </div>

              <ImageGallery
                event_id={event.id}
                images={images}
                show_delete={true}
                setImages={setImages}
              />
            </>
          ) : (
            <div className="mt-5 d-flex justify-content-center align-items-center">
               <Button className="p-3 rounded shadow" variant="outline-success" onClick={handleRedirect('admin/upload')}>
                <div className="mb-1">Upload your images to get started</div>
                <FontAwesomeIcon icon={faUpload} size="1x" bounce/>
                </Button>
            </div>
          )}
        </div>
      </>
    )
  );
};
export default ImageManager;
