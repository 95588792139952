import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

const AnimatedCameraButton = ({ onClick, size = 50, color = "currentColor" }) => {
  return (
    <button
      onClick={onClick}
      style={{
        border: "none",
        background: "none",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0",
      }}
      aria-label="Open Camera"
    >
      <FontAwesomeIcon icon={faCamera} size="3x" beatFade title="Take a picture to start" color={color}/>
      </button>
  );
};

export default AnimatedCameraButton;
