import React from "react";
import SocialMedia from "./SocialMedia";

const Footer = ({ social_urls, bgColor="dark", textColor="dark", iconsColor="light" }) => {
  return (
    <footer className={`bg-${bgColor} text-${textColor} py-3 rounded-bottom`}>
      <div className="container">
        <SocialMedia social_urls={social_urls} color={`text-${iconsColor}`} />
      </div>
    </footer>
  );
};

export default Footer;
