import React from "react";
import { FaFacebook, FaInstagram, FaGlobe } from "react-icons/fa";

const SocialMedia = ({ social_urls, color = "text-light" }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      {/* Instagram Link */}
      {social_urls.instagram && (
        <a
          href={social_urls.instagram}
          target="_blank"
          rel="noopener noreferrer"
          className={`${color} mx-3`}
        >
          <FaInstagram size={24} />
        </a>
      )}

      {/* Facebook Link */}
      {social_urls.facebook && (
        <a
          href={social_urls.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className={`${color} mx-3`}
        >
          <FaFacebook size={24} />
        </a>
      )}

      {/* Website Link */}
      {social_urls.website && (
        <a
          href={`https://${social_urls.website}`}
          target="_blank"
          rel="noopener noreferrer"
          className={`${color} mx-3`}
        >
          <FaGlobe size={24} />
        </a>
      )}
    </div>
  );
};

export default SocialMedia;
