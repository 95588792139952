// src/App.js
import React, { useState } from "react";
import AdminPanel from "./pages/AdminPanel";
import UserPanel from "./pages/UserPanel";
import UploadImg from "./pages/UploadImg";
import Header from "./components/Header";
import Tail from "./components/Tail";
import Footer from "./components/Footer";
import Loader from "./components/Loader";

import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RiTailwindCssFill } from "react-icons/ri";

function App() {
  const [event, setEvent] = useState({});
  
  const socialLinks = {
    instagram: "https://www.instagram.com/johann.events?igsh=eDRtNDFqcWVmdG1i",
    facebook: "https://www.facebook.com/share/1AQBXSqkQB/?mibextid=wwXIfr",
    website: "https://www.facebook.com/share/1AQBXSqkQB/?mibextid=wwXIfr"
  };


  return (
    <Container className="mt-4 p-2 ">
      <main className="main-content"  style={{
    minHeight: "80vh",
  }}>
      {/* retrive the event Name from /check_eid endpoint */}
      <Router>
        <Routes>
          <Route
            path="/:event_id"
            element={
              <div>
                <Header setEvent={setEvent} social_urls={socialLinks}/>

                {event !== false ? (
                  event.name ? (
                    <UserPanel
                      style={{ display: event.name ? "block" : "none" }}
                      event={event}
                    />
                  ) : (
                    // Loader
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "100vh",
                      }}
                    >
                      <Loader iconPath="/favicon.png" size={100} />
                    </div>
                  )
                ) : (
                  <div>
                    <h2 className="text-center mb-4">Event Not Found</h2>
                  </div>
                )}
              </div>
            }
          />

          {/* Route for the admin panel, e.g., http://localhost:3000/1/admin */}
          <Route
            path="/:event_id/admin"
            element={
              <>
                <Header setEvent={setEvent} social_urls={socialLinks}/>
                {event !== false ? (
                  event.name ? (
                    <AdminPanel
                      style={{ display: event.name ? "block" : "none" }}
                      event={event}
                    />
                  ) : (
                    // Loader
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "100vh",
                      }}
                    >
                      <Loader iconPath="/favicon.png" size={100} />
                    </div>
                  )
                ) : (
                  <div>
                    <h2 className="text-center mb-4">Event Not Found</h2>
                  </div>
                )}

              </>
            }
          />

          {/* Route for the admin panel, e.g., http://localhost:3000/1/admin/upload */}
          <Route
            path="/:event_id/admin/upload"
            element={
              <>
                <Header setEvent={setEvent} social_urls={socialLinks} />
                <UploadImg event={event} />
              </>
            }
          />
        </Routes>
      </Router>
      </main>
      <Footer social_urls={socialLinks} />
      <Tail></Tail>
    </Container>
  );
}

export default App;
