// Tail.js component with copy rights and Years
import React from 'react';
const Tail = () => {
    return (
        <div className="text-center mt-3">
        <p>
            <small>
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://www.mygalleryphoto.com">MyGalleryphoto</a> |{" "}
            <a href="https://www.mygalleryphoto.com/privacy">Privacy</a> |{" "}
            <a href="https://www.mygalleryphoto.com/terms">Terms</a>
            </small>
        </p>
        </div>
    );
}

export default Tail;