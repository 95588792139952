import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Button, ProgressBar, Form } from "react-bootstrap";
import axios from "axios";
import pLimit from "p-limit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faUpload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const UploadImg = ({ event }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [totalUploaded, setTotalUploaded] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [alertMessage, setAlertMessage] = useState({success: false, message: ""});
  
  // update the uploadProgress each time that totalUploaded changes
  useEffect(() => {
    if (selectedFiles.length > 0) {
      setUploadProgress(Math.round((totalUploaded / selectedFiles.length) * 100));
    }
  }
  , [totalUploaded]);

  const navigate = useNavigate();


  /** Dropzone callback to handle the files dropped */
  const onDrop = useCallback((acceptedFiles) => {
    const totalSizeInBytes = acceptedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    setSelectedFiles(acceptedFiles);
    setTotalSize((totalSizeInBytes / (1024 * 1024)).toFixed(2)); // Convert bytes to MB
    setUploadProgress(0);
    setTotalUploaded(0);
    setIsUploading(false);
    setAlertMessage({success: false, message: ""});

  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: true,
  });
  

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const totalSizeInBytes = Array.from(files).reduce(
      (acc, file) => acc + file.size,
      0
    );
    setSelectedFiles(files);
    setTotalSize((totalSizeInBytes / (1024 * 1024)).toFixed(2));
  };

  const uploadFile = async (file, uploadId) => {
    const formData = new FormData();
    formData.append("images", file);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MODEL_ENDPOINT}/images?eid=${event.id}&upload_id=${uploadId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // console.log(`Uploading ${file.name}: ${progress}%`);
          },
        }
      );
      console.log(`File ${file.name} uploaded successfully`, response.data);
      setTotalUploaded((prev) => prev + 1);
    } catch (error) {
      console.error(`Error uploading ${file.name}:`, error);
    }
  };

  const triggerImgProcessing = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_MODEL_ENDPOINT}/reload_known?eid=${event.id}`
      );
      console.log("Uploaded images processed", response.data);
    } catch (error) {
      console.error(`Error while processing the face on files:`, error);
    }

  };

  /** upload the selected files and update the progress bar */
  const handleUpload = () => {

    setAlertMessage({success: false, message: ""});

    if (selectedFiles.length === 0) {
      alert("Please select one or more images to upload.");
      return;
    }

    const uploadId = `upload_${Date.now()}`; // Generate a unique ID for this upload
    const concurrencyLimit = 3; // Maximum concurrent uploads
    const limit = pLimit(concurrencyLimit);
  
    setIsUploading(true);
    const uploadPromises = Array.from(selectedFiles).map((file) => {
      return limit(() => uploadFile(file, uploadId));
    });
  
    Promise.all(uploadPromises)
      .then(() => {
        setAlertMessage({success: true, message: `${selectedFiles.length} images uploaded successfully.`});
        console.log("All files uploaded successfully")
        triggerImgProcessing();
      })
      .catch((error) => {
        console.error("Error uploading files:", error)
        setAlertMessage({success: false, message: "An error occurred:" + error});
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleRedirect = (path) => {
    // prevent default action
    if (path) {
      return () => navigate(`/${event.id}/${path}`);
    } else {
      return () => navigate(`/${event.id}`);
    }
  }

  return (
    <div className="upload-container">
      <div className="d-flex justify-content-between">
      <Button className="m-1 btn-sm" variant="outline-dark" onClick={handleRedirect('admin')}>                  
        Home
      </Button>
      <h5>Upload images</h5>
      </div>

      {/* Drag and Drop Zone */}
      <div
        {...getRootProps()}
        className={`dropzone p-3 mb-3 border rounded bg-light ${
          isDragActive ? "border-primary" : "border-secondary"
        }`}
        style={{
          borderStyle: "dashed",
          cursor: "pointer",
          height: "400px",
          height: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            Drag & drop some images here, or click to select files (PNG, JPEG,
            JPG)
          </p>
        )}
      </div>

      {/* progress bar */}
      {isUploading && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      {selectedFiles.length > 0 && (
        <>
          <div className="mb-3">
            <p>
              Total size: {totalSize} MB ({selectedFiles.length} file
              {selectedFiles.length > 1 ? "s" : ""})
            </p>
          </div>

          <div className="text-center m-2">
            {/* uploading Spinner */}
            {isUploading && (
              <>
                {/* <span className="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span> */}
                <FontAwesomeIcon icon={faSpinner} spin color="#0d6efd" />
                <span className="ms-2">Uploading</span>
              </>
            )}

            {!isUploading && uploadProgress == 0 && (
              <Button variant="outline-success" onClick={handleUpload}>
                <FontAwesomeIcon icon={faUpload} size="1x" />
                <span className="m-1">
                  Upload {selectedFiles.length} selected images
                </span>
              </Button>
            )}
          </div>
        </>
      )}

      {/* alert success / fail message */}
      {alertMessage.message && (
        <div
          className={`alert ${
            alertMessage.success ? "alert-success" : "alert-danger"
          } mt-3`}
          role="alert"
          dangerouslySetInnerHTML={{ __html: alertMessage.message }}
        ></div>
      )}
    </div>
  );
};

export default UploadImg;
